<template>
	<div class="flex items-center justify-center bg-gray-50 pt-4 sm:pt-12 pb-12 px-4 sm:px-6 lg:px-8">
		<div class="max-w-lg w-full">
			<div>
				<h2 class="mt-6 text-center text-3xl font-extrabold text-accent">
					Caso clínico
				</h2>
				<p class="mt-3 text-center text-sm text-gray-600">
					Para darle un impulso al desempeño en tu juego y recibir puntos extra, comparte un caso clínico de alguno de tus pacientes; haremos un compendio de todos los casos clínicos de los participantes en forma de eBook, que podrás descargar y que será de utilidad para tu práctica profesional.
				</p>
				<p class="mt-4 text-center text-sm text-green-600 font-semibold" v-if="state.user.case">
					¡Muy bien, el caso ya se encuentra registrado!
				</p>
			</div>

			<form class="mt-8 space-y-7 sm:px-8" @submit.prevent="submitForm" v-if="state.user.case == undefined">
				<div>

					<div>
						<label for="name" class="sr-only">Título</label>
						<input v-model="state.model.title" type="text" id="name" autocomplete="off" placeholder="Título del caso" :disabled="state.sending" class="shadow-sm appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-accent focus:z-10 sm:text-sm" />
					</div>
					<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
						<div v-if="v$.model.title.$error">{{v$.model.title.$errors[0].$message}}</div>
					</div>

					<div class="overflow-hidden relative w-full mt-3 cursor-pointer" v-if="state.model.file==null">
						<div class="group relative w-full flex justify-center bg-accent text-white font-medium text-sm py-2 px-4 items-center rounded-md shadow-sm">
							<span class="absolute left-0 inset-y-0 flex items-center pl-3">
								<DocumentTextIcon class="h-5 w-5 text-white group-hover:text-white opacity-50" aria-hidden="true" />
							</span>
							Adjuntar documento
						</div>
						<input class="cursor-pointer absolute block opacity-0 left-0 top-0 w-full" type="file" @change="selectedFile">
					</div>
					<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
						<div v-if="v$.model.file.$error">{{v$.model.file.$errors[0].$message}}</div>
					</div>

					<div class="flex items-center justify-between mt-5 ml-1" v-if="state.model.file != null">
						<div class="text-sm flex items-center truncate">
							<DocumentTextIcon class="h-5 w-5 text-accent mr-2" aria-hidden="true" />
							{{state.model.file.name}}
						</div>

						<div>
							<XIcon class="h-5 w-5 text-accent mr-2 cursor-pointer" @click="cleanFile" aria-hidden="true" />
						</div>
					</div>

					<div class="text-sm ml-1 mt-2 font-medium text-red-500" v-if="state.showMsgLimit">
						Peso máximo permitido del documento: 10MB.
					</div>
				</div>

				<div>
					<button type="submit" v-if="!state.sending" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
						<span class="absolute left-0 inset-y-0 flex items-center pl-3">
							<CloudUploadIcon class="h-5 w-5 text-white group-hover:text-white opacity-50" aria-hidden="true" />
						</span>
						Subir caso
					</button>

					<div v-if="state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
						Espera un momento...
					</div>
				</div>
			</form>

			<div class="mt-8" v-if="state.user.case">
				<div class="rounded-md shadow-sm -space-y-px">
					<div class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md sm:text-sm truncate">
						<span class="mr-2 font-medium text-accent">Título:</span>
						<span class="text-black font-medium">{{state.user.case.title}}</span>
					</div>
					<div class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md sm:text-sm font-medium">
						<div v-if="state.user.casePoints > 0">
							{{state.user.casePoints}} puntos asignados.
						</div>

						<div class="text-gray-500 flex items-cemter" v-else>
							<HandIcon class="h-5 w-5 text-gray-400 mr-2 cursor-pointer" @click="state.model.file = null" aria-hidden="true" />
							<div class="font-normal">Aún no se asignan puntos al caso.</div>
						</div>
					</div>
				</div>

				<a :href="state.user.case.url" target="_blank" v-if="!state.sending" class="mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
					<span class="absolute left-0 inset-y-0 flex items-center pl-3">
						<CloudDownloadIcon class="h-5 w-5 text-white group-hover:text-white opacity-50" aria-hidden="true" />
					</span>
					Descargar caso
				</a>

				<div v-if="state.sending" class="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
					Espera un momento...
				</div>

				<div class="mt-6 flex items-center justify-center" @click="tryRemove" v-if="!state.sending">
					<TrashIcon class="h-4 w-4 text-accent mr-2 cursor-pointer"  aria-hidden="true" />
					<div class="text-xs text-accent cursor-pointer">Eliminar</div>
				</div>
			</div>

			<FooterPublic class="mt-10" />
		</div>
	</div>

	<DialogRemove
		v-if="state.showRemove"
		title="Se eliminará el caso"
		text="En caso de tener puntos asignados, se borrarán también de la suma total."
		@cancel="state.showRemove=false"
		@proceed="remove" />
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import { LockClosedIcon, DocumentTextIcon } from '@heroicons/vue/solid'
import {  BellIcon, CloudUploadIcon, XIcon, CloudDownloadIcon, HandIcon, TrashIcon } from '@heroicons/vue/outline'
import { onMounted, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Cases from '@/firebase/cases'
import FooterPublic from '@/components/core/FooterPublic'
import Selectable from '@/components/core/Selectable'
import Misc from '@/services/misc'
import DialogRemove from '@/components/core/DialogRemove'

export default {
		components: {
			LockClosedIcon,
			FooterPublic,
			Selectable,
			BellIcon,
			DocumentTextIcon,
			CloudUploadIcon,
			CloudDownloadIcon,
			XIcon,
			HandIcon,
			TrashIcon,
			DialogRemove
		},
		setup() {
			const store = useStore();
			const route = useRoute();
			const router = useRouter();

			const user = computed(() => store.getters.user);

			const state = reactive({
				model: {
					title: '',
					guid: null,
					file: null,
				},
				user: user,
				sending: false,
				showRemove: false,
				showMsgLimit: false
			});

			const uploaded = computed(() => store.getters.fileUploaded);

			watch(uploaded, (current, old) => {
				if (uploaded.value === true) {
					state.model.file = null;
					state.model.guid = null;
					state.model.title = '';
					state.sending = false;
				}
			});

			const rules = computed(() => {
				return {
					model: {
						title: { 
							required: helpers.withMessage('El título es requerido.', required),
							minLength: helpers.withMessage('Título no válido', minLength(3)),
							maxLength: helpers.withMessage('Máximo 60 caracteres', maxLength(40)),
						},
						file: { 
							required: helpers.withMessage('Falta adjuntar el caso.', required),
						}
					}
				}
			});

			const v$ = useValidate(rules, state);

			function submitForm() {
				v$.value.$validate()

				if (!v$.value.$error) {
					state.sending = true;
					Cases.upload(user.value, state.model);
				}
			}

			function selectedFile(event) {
				if (event.target.files[0].size < 10000000) {
					state.model.file = event.target.files[0];
					state.model.guid = Misc.guid();
					state.showMsgLimit = false;
				} else {
					state.showMsgLimit = true;
				}
			}
			
			function cleanFile() {
				if (state.sending == false) {
					state.model.file = null
				}
			}

			function tryRemove() {
				state.showRemove = true;
			}

			function remove() {
				state.showRemove = false;
				state.sending = true;

				Cases.remove(user.value)
					.then(response => {
						if (response) {
							store.dispatch('setNotification', {type: true, title: 'Caso eliminado', content: 'Te invitamos a subir un caso para sumar puntos.'});
						} else {
							store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión.'});
						}
						state.sending = false;
					});
			}

			return {
				state,
				submitForm,
				v$,
				selectedFile,
				cleanFile,
				tryRemove,
				remove
			}
		}
}
</script>