import firebase, {db, auth, fc, st} from './connection'
import store from '../store.js'
import router from '../router.js'
import { sortedIndex } from 'lodash';

export default {
    getAll() {
        let ref = db.collection('cases');

        return ref.get()
                    .then(query => {
                        return query;
                    }).catch(error => {
                        return error;
                    });
    },

    update(uid, model) {
        let ref = db.collection('cases').doc(uid);

        return ref.update(model)
                    .then( () => {
                        return true;
                    }).catch(error => {
                        return error;
                    });
    },

    assignPoints(model) {
        let refCase = db.collection('cases').doc(model.id);
        let refUser = db.collection('users').doc(model.uid);
        
        let batch = db.batch();

        batch.update(refCase, { 
            points: model.points ,
            pointsAssignatedBy: store.getters.user.uid
        });

        batch.update(refUser, { 
                    casePoints: model.points,
                    pointsAssignatedBy: store.getters.user.uid
                });

        return batch.commit()
                    .then( () => {
                        return true;
                    })
                    .catch(error => {
                        return error;
                    });
    },

    remove(user) {
        let stRef = st.ref().child('cases/'+user.case.filename);
        stRef.delete();

        let ref = db.collection('cases').where('uid', '==', user.uid);

        return ref.get().then(query => {
                let doc = query.docs[0];

                let refCase = db.collection('cases').doc(doc.id);
                let refUser = db.collection('users').doc(user.uid);

                let batch = db.batch();

                batch.delete(refCase);
                batch.update(refUser, {
                            casePoints: 0,
                            case: null
                        });
                
                return batch.commit()
                            .then( () => {
                                let user = store.getters.user;
                                user.casePoints = 0;
                                user.case = null;
                                store.dispatch('setUser', user);
                                return true;
                            })
                            .catch(error => {
                                return error;
                            });
            });

        
    },

    upload(user, model) {
		let refUser = db.collection('users').doc(user.uid);
		let refCases = db.collection('cases').doc();
		let uploadTask = st.ref('cases/' + model.guid).put(model.file);

        store.dispatch('setFileUploading', true);
        store.dispatch('setFileUploaded', false);

		uploadTask.on('state_changed', snapshot => {
						let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        store.dispatch('setFileProgress', progress);
					}, error => {
                        console.log(error);
                        store.dispatch('setFileUploading', false);
					}, () => {
                        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                                let obj = {
                                        name: user.name + ' ' + user.paterno,
                                        uid: user.uid, 
                                        title: model.title,
                                        filename: model.guid,
                                        url: downloadURL,
                                        points: 0,
                                        pointsAssignatedBy: null,
                                        createdAt: firebase.firestore.FieldValue.serverTimestamp()
                                    };
                                
                                if (user.materno) {
                                    obj.name += ' ' + user.materno;
                                }
                                
                                let modelCase = {
                                    title: model.title,
                                    filename: model.guid,
                                    url: downloadURL,
                                    createdAt: firebase.firestore.FieldValue.serverTimestamp()
                                }
                                
                                let batch = db.batch();

                                batch.set(refCases, obj);
                                batch.update(refUser, {case: modelCase});

                                batch.commit()
                                    .then( () => {
                                        let user = store.getters.user;
                                        user.case = modelCase;
                                        store.dispatch('setUser', user);
                                        store.dispatch('setFileUploading', false);
                                        store.dispatch('setFileUploaded', true);
                                        store.dispatch('setNotification', {type: true, title: '¡Listo!', content: 'El caso se subió bien.'});
                                    })
                                    .catch( error => {
                                        console.log(error);
                                    });
                            });
					});
    }
}